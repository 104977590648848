<template>
  <div class="modal">
    <div class="modal__overlay" @click="$emit('close')"></div>
    <div class="modal__block palilpScrollbar">
      <div class="modal__header d-flex align-items-center">
        <button class="btn btn-control me-2" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="" />
        </button>
        <h3 class="modal__title">Import from LendingPad</h3>
      </div>
      <div>
        <div class="modal__search">
          <div class="modal__search_wrapper">
            <img alt="Icon search" src="@/assets/icons/icon-search.svg" />
            <input
              aria-label="Search"
              placeholder="Search Borrower or Co Borrower Name"
              v-model="searchField"
              type="text"
            />
          </div>
        </div>
        <page-spinner v-if="ui.loading" />
        <div class="modal__body" v-else>
          <div class="modal__container border-bottom-0">
            <div class="modal__container_title mt-3">
              New Leads from LendingPad
            </div>
            <div class="borrowers__list">
              <div class="modal-alert modal-alert_advice text-center" v-if="tableBorrowerSearch.length === 0">
                No Records Found
              </div>
              <table class="table table-hover">
                <tbody>
                  <tr
                    v-for="borrower in tableBorrowerSearch"
                    :key="borrower.id"
                    @click="selectBorrower(borrower)"
                  >
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="borrower-avatar grey">
                          <div>
                            {{ borrower.imageName }}
                          </div>
                        </div>
                        <div class="ms-3">
                          <div class="borrower-name">
                            {{ borrower.fullName }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="conventional d-flex align-items-center">
                        ${{ borrower.loanAmount }} • {{ borrower.product }}
                        {{ borrower.loanRate }}
                      </div>
                    </td>
                    <td>
                      <div class="conventional d-flex align-items-center">
                        {{ timeAgo(borrower.lastUpdate) }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="text-center" v-if="ui.loadMore">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSpinner from "../../../components/pageSpinner";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "PreApprovalLetterImportLendingPad",
  components: { PageSpinner },
  data() {
    return {
      searchField: "",
      lendingPad: {
        borrowers: []
      },
      filterSelected: "All",
      ui: {
        loading: false,
        loadMore: false,
        psLender: null
      },
      paging: {
        offset: 0,
        limit: 50,
        total: 0
      }
    };
  },
  computed: {
    tableBorrowerSearch() {
      return this.lendingPad.borrowers.filter(borrower => {
        return (
          borrower.fullName
            .toLowerCase()
            .includes(this.searchField.toLowerCase()) ||
          borrower.coBorrowerFullName
            .toLowerCase()
            .includes(this.searchField.toLowerCase())
        );
      });
    }
  },
  methods: {
    getBorrowers() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/pre-approved-letters/lp-leads", {
          params: {
            offset: this.paging.offset,
            limit: this.paging.limit
          }
        })
        .then(res => {
          if (res.data.data.length > 0) {
            this.lendingPad.borrowers = res.data.data;
            this.paging = res.data.paging;
          }
        })
        .catch(err => this.alertError(err.response.statusText))
        .finally(() => (this.ui.loading = false));
    },
    loadMore() {
      this.ui.loadMore = true;
      this.$http
        .get("/api/v1/pre-approved-letters/lp-leads", {
          params: {
            offset: this.paging.offset,
            limit: this.paging.limit
          }
        })
        .then(res => {
          if (res.data.data.length > 0) {
            this.lendingPad.borrowers.push(...res.data.data);
            this.paging = res.data.paging;
          }
        })
        .catch(err => this.alertError(err.response.statusText))
        .finally(() => (this.ui.loadMore = false));
    },
    selectBorrower(borrower) {
      this.$emit("select-borrower", { borrower: borrower, type: "lendingpad" });
    },
    loadMoreLPRecords() {
      if (
        this.paging.total > 0 &&
        this.lendingPad.borrowers.length < this.paging.total
      ) {
        this.paging.offset = this.paging.offset + 10;
        this.loadMore();
      }
    },
    scrollEventForLoadMore() {
      let element = document.querySelector(".palilpScrollbar");
      var scrollerEndPoint = element.scrollHeight - element.clientHeight;
      var divScrollerTop = element.scrollTop;
      if (divScrollerTop === scrollerEndPoint && !this.ui.loadMore) {
        this.loadMoreLPRecords();
      }
    }
  },
  beforeMount() {
    this.getBorrowers();
  },
  mounted() {
    document.querySelector("html").style.overflow = "hidden";
    const PalilpScrollbar = document.querySelector(".palilpScrollbar");
    this.ui.psLender = new PerfectScrollbar(PalilpScrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
    PalilpScrollbar.addEventListener("scroll", this.scrollEventForLoadMore);
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__block {
    &.palilpScrollbar {
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  &__body {
    padding-top: 10px;
  }
  &__container {
    &_title {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  &__search {
    background-color: #ffffff;
    max-width: 100%;
    margin: 70px 0 0 0;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid #e7e8e8;

    &_wrapper {
      background-color: #ffffff;
      max-width: 100%;
      border-radius: 0;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      margin: 0;

      input {
        width: 95%;
        border-radius: 0;
        border: none;

        &:focus-visible {
          border: none;
          outline: none;
        }
      }
    }
  }
  .borrowers__list {
    tbody {
      tr {
        &:hover {
          cursor: pointer;
        }

        &:nth-child(even) {
          background-color: white !important;
        }

        .conventional {
          opacity: 0.5;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }

    .borrower-avatar {
      height: 55px;
      width: 55px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      background-color: #ec2254;
      text-align: center;

      div {
        color: #ffffff;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin: 50% 0 0 50%;
        transform: translate(-50%, -50%);
      }
    }

    .grey {
      background-color: #d8d8d8;

      div {
        color: #4a4a4a;
      }
    }

    .borrower-from {
      opacity: 0.46;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }

    .borrower-name,
    .borrower-conventional {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      font-weight: normal;
    }

    .borrower-conventional {
      margin-left: 12px;
      opacity: 0.7;
    }

    .borrower-info {
      margin-left: 24px;
    }
  }
}
</style>
