<template>
  <div class="modal">
    <div class="modal__overlay" @click="$emit('close')"></div>
    <div class="modal__block">
      <div class="modal__header d-flex align-items-center">
        <button class="btn btn-control me-2" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="" />
        </button>
        <h3 class="modal__title">Import from VisionCRM</h3>
      </div>
      <div>
        <div class="modal__search">
          <div class="modal__search_wrapper">
            <img alt="Icon search" src="@/assets/icons/icon-search.svg" />
            <input
              aria-label="Search"
              placeholder="Search Lead Name"
              v-model="searchField"
              type="text"
            />
          </div>
        </div>
        <div class="modal__body">
          <div class="modal__container border-bottom-0">
            <div class="modal__filters">
              <button
                v-for="filter in filters"
                :key="filter.title + '_key'"
                @click="selectFilter(filter.title)"
                :class="{ active: filter.title === filterSelected }"
              >
                {{ filter.title }}
              </button>
            </div>
            <page-spinner v-if="ui.loading" />
            <div v-else>
              <div class="modal__container_title mt-3">
                New Leads from VisionCRM
              </div>
              <div class="borrowers__list">
                <table class="table table-hover">
                  <tbody>
                    <tr
                      v-for="borrower in tableBorrowerSearch"
                      :key="borrower.id"
                      @click="selectBorrower(borrower)"
                    >
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="borrower-avatar grey">
                            <div>
                              {{ borrower.imageName }}
                            </div>
                          </div>
                          <div class="ms-3">
                            <div class="borrower-name">
                              {{ borrower.fullName }}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="conventional d-flex align-items-center">
                          ${{ borrower.loanAmount }} • {{ borrower.product }}
                          {{ borrower.loanRate }}
                        </div>
                      </td>
                      <td>
                        <div class="conventional d-flex align-items-center">
                          {{ timeAgo(borrower.lastUpdate) }}
                        </div>
                      </td>
                    </tr>
                    <tr v-if="tableBorrowerSearch.length === 0">
                      <td>
                        <div class="modal-alert modal-alert_advice text-center">
                          No Records Found
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSpinner from "../../../components/pageSpinner";
export default {
  name: "PreApprovalLetterImportVisionCRM",
  components: { PageSpinner },
  data() {
    return {
      searchField: "",
      visionCrm: {
        borrowers: []
      },
      filters: [
        { title: "All" },
        { title: "New" },
        { title: "Viewed" },
        { title: "Hot Leads" },
        { title: "Prospecting" },
        { title: "Quote Sent" },
        { title: "App Received" },
        { title: "Submitted" },
        { title: "Funded" },
        { title: "Sent to Lendingpad" },
        { title: "Dead" }
      ],
      filterSelected: "All",
      ui: {
        loading: false
      }
    };
  },
  computed: {
    tableBorrowerSearch() {
      if (this.filterSelected !== "All") {
        return this.visionCrm.borrowers.filter(borrower => {
          return (
            borrower.fullName
              .toLowerCase()
              .includes(this.searchField.toLowerCase()) &&
            borrower.status === this.filterSelected
          );
        });
      }

      return this.visionCrm.borrowers.filter(borrower => {
        return borrower.fullName
          .toLowerCase()
          .includes(this.searchField.toLowerCase());
      });
    }
  },
  methods: {
    getBorrowers() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/pre-approved-letters/crm-leads")
        .then(res => (this.visionCrm.borrowers = res.data))
        .catch(err => this.alertError(err.response.statusText))
        .finally(() => (this.ui.loading = false));
    },
    selectFilter(filterTitle) {
      this.filterSelected = filterTitle;
    },
    selectBorrower(borrower) {
      this.$emit("select-borrower", { borrower: borrower, type: "vision" });
    }
  },
  beforeMount() {
    this.getBorrowers();
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  &__body {
    padding-top: 10px;
  }
  &__container {
    &_title {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  &__filters {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 10px;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 4px; /* height of horizontal scrollbar ← You're missing this */
      width: 4px; /* width of vertical scrollbar */
      border: 1px solid #d5d5d5;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background: #aeaeae;
      border-radius: 10px;
    }

    button {
      height: fit-content;
      min-width: fit-content;
      border: 1px solid #cccccc;
      border-radius: 16px;
      color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      padding: 7px 11px;
      background: none;
      transition: all 0.3s;
      &:not(:last-child) {
        margin-right: 4px;
      }

      &.active {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
  &__search {
    background-color: #ffffff;
    max-width: 100%;
    margin: 70px 0 0 0;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid #e7e8e8;

    &_wrapper {
      background-color: #ffffff;
      max-width: 100%;
      border-radius: 0;
      border-bottom: solid 1px #065ae4;
      margin: 0;

      input {
        width: 95%;
        border-radius: 0;
        border: none;

        &:focus-visible {
          border: none;
          outline: none;
        }
      }
    }
  }
  .borrowers__list {
    tbody {
      tr {
        &:hover {
          cursor: pointer;
        }
        &:nth-child(even) {
          background-color: white !important;
        }

        .conventional {
          opacity: 0.5;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }

    .borrower-avatar {
      height: 55px;
      width: 55px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      background-color: #ec2254;
      text-align: center;

      div {
        color: #ffffff;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin: 50% 0 0 50%;
        transform: translate(-50%, -50%);
      }
    }

    .grey {
      background-color: #d8d8d8;

      div {
        color: #4a4a4a;
      }
    }

    .borrower-from {
      opacity: 0.46;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }

    .borrower-name,
    .borrower-conventional {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      font-weight: normal;
    }

    .borrower-conventional {
      margin-left: 12px;
      opacity: 0.7;
    }

    .borrower-info {
      margin-left: 24px;
    }
  }
}
</style>
