<template>
  <div class="form-group">
    <label :for="fieldId" :aria-describedby="fieldId + 'Help'">
      {{ label }}
    </label>
    <div :class="{ 'input-with-icon': icon }">
      <span class="icon" v-if="icon">
        {{ icon }}
      </span>
      <input
        :type="typeField"
        class="form-control"
        :id="fieldId"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
      />
    </div>

    <small :id="fieldId + 'Help'" class="form-text text-muted">
      {{ help }}
    </small>
  </div>
</template>

<script>
export default {
  name: "BaseField",
  props: {
    value: { default: "" },
    typeField: { type: String, default: "text" },
    label: { type: String, default: "" },
    fieldId: { type: String, default: "" },
    help: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    icon: { type: String }
  }
};
</script>
