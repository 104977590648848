<template>
  <div class="approval-info border-bottom-light">
    <h2 class="approval-info__title">
      Prospect
    </h2>
    <div class="approval-info__prospects" v-if="type === null">
      <button class="approval-info__prospect" @click="newProspect">
        <img src="@/assets/icons/bold-icon add.svg" />
        New Prospect
      </button>
      <span class="approval-info__text">Or Import from</span>
      <button class="approval-info__prospect" @click="ui.visionModal = true">
        <img src="@/assets/icons/logo-VisionCRM.svg" />
        VisionCRM
      </button>
      <button
        class="approval-info__prospect mt-3"
        @click="ui.lendingPadModal = true"
      >
        <img src="@/assets/icons/logo-lendingpad.svg" />
        LendingPad
      </button>
    </div>
    <div class="approval-info__selected-prospect" v-else-if="type === 'vision'">
      <div class="row">
        <div class="col-8">
          <div class="d-flex align-items-center">
            <img src="@/assets/icons/logo-VisionCRM.svg" />
            <div class="approval-info__company-name">VisionCRM Lead</div>
            <button
              class="btn btn-control"
              content="Reset to Default"
              v-tippy="{ placement: 'bottom', arrow: true }"
              @click="resetProspect"
            >
              <img src="@/assets/icons/icon refresh.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <button class="btn btn-link" @click="resetSource">
            Change
          </button>
        </div>
      </div>
    </div>
    <div
      class="approval-info__selected-prospect"
      v-else-if="type === 'lendingpad'"
    >
      <div class="row">
        <div class="col-8">
          <div class="d-flex align-items-center">
            <img src="@/assets/icons/logo-lendingpad.svg" />
            <div class="approval-info__company-name">LendingPad Lead</div>
            <button
              class="btn btn-control"
              content="Reset to Default"
              v-tippy="{ placement: 'bottom', arrow: true }"
              @click="resetProspect"
            >
              <img src="@/assets/icons/icon refresh.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <button class="btn btn-link" @click="resetSource">
            Change
          </button>
        </div>
      </div>
    </div>
    <div class="approval-info__selected-prospect" v-else>
      <div class="row">
        <div class="col-8 my-auto">
          <div class="d-flex align-items-center">
            <img src="@/assets/icons/icon-profile.svg" />
            <div class="approval-info__company-name">New Prospect</div>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <button class="btn btn-link" @click="resetSource">
            Change
          </button>
        </div>
      </div>
    </div>

    <div class="approval-info__borrower-info" v-if="type !== null">
      <div class="row">
        <div class="col-md-6">
          <base-field
            label="First Name"
            field-id="firstNameField"
            class="form-group_stronger form-group_small full-width"
            v-model="borrower.firstName"
            :placeholder="'First Name'"
          />
        </div>
        <div class="col-md-6">
          <base-field
            label="Last Name"
            field-id="lastNameField"
            class="form-group_stronger form-group_small full-width"
            v-model="borrower.lastName"
            :placeholder="'Last Name'"
          />
        </div>
        <div class="col-12" v-if="ui.showCoBorrowerSection">
          <div class="row">
            <div class="col-md-6 mt-3">
              <base-field
                label="Co First"
                field-id="coFirstField"
                class="form-group_stronger form-group_small full-width"
                v-model="coBorrower.firstName"
                :placeholder="'First Name'"
              />
            </div>
            <div class="col-10 col-md-5 mt-3">
              <base-field
                label="Co Last"
                field-id="coLastField"
                class="form-group_stronger form-group_small full-width"
                v-model="coBorrower.lastName"
                :placeholder="'Last Name'"
              />
            </div>
            <div
              class="col-1 d-flex align-items-end ps-0"
              @click="removeCoBorrower"
            >
              <button class="btn btn-control approval-info__remove-co-borrower">
                <img src="@/assets/icons/icon-delete.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2" v-else>
          <div
            class="d-flex approval-info__add-borrower cursor-pointer"
            @click="addCoBorrower"
          >
            <img src="@/assets/icons/icon-add-blue.svg" alt="" />
            <span>Add Co Borrower</span>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <base-field
            label="Program"
            field-id="programField"
            type-field="text"
            class="form-group_stronger form-group_small full-width"
            v-model="borrower.program"
          />
        </div>
        <div class="col-md-6 mt-3">
          <base-field
            label="Purchase price"
            field-id="purchasePriceField"
            type-field="number"
            icon="$"
            class="form-group_stronger form-group_small full-width"
            v-model="borrower.purchasePrice"
            @input="lastModified='purchasePrice'"
            :placeholder="'0'"
          />
        </div>
        <div class="col-md-6 mt-3">
          <base-field
            label="LTV"
            field-id="ltv"
            type-field="number"
            icon="%"
            class="form-group_stronger form-group_small full-width"
            v-model="borrower.ltv"
            @input="lastModified='ltv'"
          />
        </div>
        <div class="col-md-6 mt-3">
          <base-field
            label="Loan amount"
            field-id="amountField"
            type-field="number"
            icon="$"
            class="form-group_stronger form-group_small full-width"
            v-model="borrower.amount"
            @input="lastModified='loanAmount'"
            :placeholder="'0'"
          />
        </div>
        <div class="col-md-6 mt-3">
          <base-field
            label="Down payment"
            field-id="downPaymentField"
            type-field="number"
            icon="$"
            class="form-group_stronger form-group_small full-width"
            v-model="borrower.downPayment"
            @input="lastModified='downPayment'"
            :placeholder="'0'"
          />
        </div>

        <div class="col-md-12 mt-3">
          <base-field
            label="Property Address"
            field-id="addressField"
            class="form-group_stronger form-group_small full-width"
            v-model="borrower.address"
            :placeholder="'e.g. 1595 Maple Street, Los Angeles, CA 90017'"
            :help="borrower.isMailingAddress ? '* Pulling from the Mailing Address.' : ''"
          />
        </div>
      </div>
    </div>

    <!--  Modals  -->
    <transition mode="out-in" name="component-fade">
      <pro-approval-import-lending-pad
        v-if="ui.lendingPadModal"
        @close="ui.lendingPadModal = false"
        @select-borrower="selectVisionBorrower"
      />
    </transition>
    <transition mode="out-in" name="component-fade">
      <pre-approval-letter-import-vision-c-r-m
        v-if="ui.visionModal"
        @close="ui.visionModal = false"
        @select-borrower="selectVisionBorrower"
      />
    </transition>
  </div>
</template>

<script>
import ProApprovalImportLendingPad from "../modals/PreApprovalLetterImportLendingPad";
import PreApprovalLetterImportVisionCRM from "../modals/PreApprovalLetterImportVisionCRM";
import BaseField from "../../../components/form/BaseField";

export default {
  name: "ProApprovalLetterProspect",
  components: {
    BaseField,
    ProApprovalImportLendingPad,
    PreApprovalLetterImportVisionCRM
  },
  props: {
    borrowerData: {
      type: Object,
      default: () => ({ firstName: "", lastName: "" })
    },
    coBorrowerData: {
      type: Object,
      default: () => ({ firstName: "", lastName: "" })
    },
    source: { type: String, default: "custom" },
    loanAmount: { type: String, default: "0" },
    purchasePrice: { type: String, default: "0" },
    program: { type: String, default: "" },
    downPayment: { type: String, default: "0" },
    propertyAddress: { type: String, default: "" }
  },
  data() {
    return {
      ui: {
        lendingPadModal: false,
        visionModal: false,
        showCoBorrowerSection: this.coBorrowerData.firstName ? true : false
      },
      borrower: {
        firstName: this.borrowerData.firstName,
        lastName: this.borrowerData.lastName,
        amount: this.loanAmount || 0,
        purchasePrice: this.purchasePrice || 0,
        ltv: 0,
        program: this.program || "",
        downPayment: this.downPayment || 0,
        address: this.propertyAddress,
        isMailingAddress: false
      },
      type: this.source,
      coBorrower: {
        firstName: this.coBorrowerData.firstName,
        lastName: this.coBorrowerData.lastName
      },
      lastModified: '',
    };
  },
  methods: {
    selectVisionBorrower(e) {
      this.borrower = {
        firstName: e.borrower.borrowerFirstName,
        lastName: e.borrower.borrowerLastName,
        amount: e.borrower.loanAmount,
        purchasePrice: e.borrower.purchasePrice,
        address: e.borrower.address,
        isMailingAddress: e.borrower.isMailing
      };

      this.coBorrower = {
        firstName: e.borrower.coBorrowerFirstName,
        lastName: e.borrower.coBorrowerLastName
      };

      this.type = e.type;
      if (e.type === "vision") {
        this.ui.visionModal = false;
      } else if (e.type === "lendingpad") {
        this.ui.lendingPadModal = false;
      }
    },
    addCoBorrower() {
      this.ui.showCoBorrowerSection = true;
    },
    newProspect() {
      this.type = "custom";
      this.borrower = {
        firstName: "",
        lastName: "",
        amount: "0",
        address: ""
      };
    },
    resetProspect() {
      this.borrower = {
        firstName: "",
        lastName: "",
        amount: "",
        downPayment: "",
        program: "",
        purchasePrice: "",
        address: ""
      };
      this.removeCoBorrower();
    },
    resetSource() {
      this.type = null;
      this.resetProspect();
    },
    removeCoBorrower() {
      this.coBorrower = {
        firstName: "",
        lastName: ""
      };
      this.ui.showCoBorrowerSection = false;
    },
    recalculateBasedOnPurchasePrice() {
      this.borrower.amount = Number(this.borrower.purchasePrice) - Number(this.borrower.downPayment);
      this.borrower.ltv = Number(Number(this.borrower.amount) ? (Number(this.borrower.amount) / Number(this.borrower.purchasePrice)) * 100 : 0).toFixed(2);
    },
    recalculateBasedOnLTV() {
      this.borrower.amount = Number(Number(this.borrower.purchasePrice) * (this.borrower.ltv / 100)).toFixed(2);
      this.borrower.downPayment = Number(this.borrower.purchasePrice) - Number(this.borrower.amount);
    },
    recalculateBasedOnDownPayment() {
      this.borrower.purchasePrice = Number(this.borrower.amount) + Number(this.borrower.downPayment);
      this.borrower.amount = Number(this.borrower.purchasePrice) - Number(this.borrower.downPayment);
      this.borrower.ltv = Number(Number(this.borrower.amount) ? (Number(this.borrower.amount) / Number(this.borrower.purchasePrice)) * 100 : 0).toFixed(2);
    },
    recalculateBasedOnLoanAmount() {
      this.borrower.purchasePrice = Number(this.borrower.amount) + Number(this.borrower.downPayment);
      this.borrower.ltv = Number(Number(this.borrower.amount) ? (Number(this.borrower.amount) / Number(this.borrower.purchasePrice)) * 100 : 0).toFixed(2);
    }
  },
  watch: {
    borrower: {
      handler(val) {
        this.$emit("update-borrower", val);
      },
      deep: true
    },
    coBorrower: {
      handler(val) {
        this.$emit("update-coBorrower", val);
      },
      deep: true
    },
    type: {
      handler(val) {
        this.$emit("update-source", val);
      },
      deep: true
    },
    'borrower.purchasePrice': function() {
      if (this.lastModified === 'purchasePrice') {
        this.recalculateBasedOnPurchasePrice();
      }
    },
    'borrower.ltv': function () {
      if (this.lastModified === 'ltv') {
        this.recalculateBasedOnLTV();
      }
    },
    // 'borrower.downPayment': function () {
    //   if (this.lastModified === 'downPayment') {
    //     this.recalculateBasedOnDownPayment();
    //   }
    // },
    // 'borrower.amount': function () {
    //   if (this.lastModified === 'loanAmount') {
    //     this.recalculateBasedOnLoanAmount();
    //   }
    // }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.borrower.ltv = Number(Number(this.borrower.amount) ? (Number(this.borrower.amount) / Number(this.borrower.purchasePrice)) * 100 : 0).toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped>
.approval-info {
  padding: 20px 0 0 0;

  &.border-bottom-light {
    border-color: #dcdcdc;
  }

  &__prospects {
    padding: 0 30px 23px 30px;
  }

  &__add-borrower {
    width: fit-content;
    align-items: center;

    span {
      display: inline-block;
      margin-left: 4px;
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  &__remove-co-borrower {
    width: 36px;
    height: 36px;
    img {
      width: 24px;
      height: 24px;
    }
  }

  &__borrower-info {
    padding: 20px 30px;
    border-top: 1px solid #dcdcdc;
  }

  &__selected-prospect {
    border-top: 1px solid #dcdcdc;
    padding: 11px 30px;
  }

  &__company-name {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 4px;
  }

  &__title {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;
    padding: 0 30px;
  }

  &__prospect {
    position: relative;
    width: 100%;
    height: 44px;
    text-align: center;
    border: 1px solid #4c4c4c;
    border-radius: 8px;
    background: none;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    transition: all 0.3s;
    padding: 0 30px;

    &:hover {
      background: #f5f5f5;
    }

    img {
      position: absolute;
      left: 22px;
      top: 9px;
    }
  }

  &__text {
    display: inline-block;
    width: 100%;
    margin: 18px auto;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
  }
}
</style>
