<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Edit MLO Details</h3>
        <button class="btn btn-close" @click="$emit('close')"></button>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-bottom-0">
          <div class="row">
            <div class="col-md-6">
              <base-field label="First Name" field-id="firstNameField" class="full-width" v-model="agent.firstName" />
            </div>
            <div class="col-md-6">
              <base-field label="Last Name" field-id="lastNameField" class="full-width" v-model="agent.lastName" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <base-field label="Position" field-id="positionField" class="full-width" v-model="agent.position" />
            </div>
            <div class="col-md-3">
              <base-field label="NMLS" field-id="nmlsField" class="full-width" v-model="agent.nmls" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <base-field v-model="agent.phone" field-id="agentField" class="full-width" label="Phone Number" />
            </div>
          </div>
          <div class="row mt-3">
            <base-field v-model="agent.email" field-id="emailField" label="Email" class="full-width" />
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <base-field v-model="agent.signature" field-id="signatureField" label="Signature Text" class="full-width" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="signature-preview">
              <div class="signature-preview__label">Your Signature (Preview)</div>
              <div class="signature-preview__body">
                {{ agent.signature }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <base-button title="Save" action="secondary-default" @click-btn="saveMlo" :loading="ui.saving" />
        <base-button title="Discard" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
import BaseField from "../../../components/form/BaseField";
export default {
  name: 'PreApprovalLetterMloEditModal',
  components: {BaseField, BaseButton},
  props: {
    mlo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      agent: {
        id: this.mlo.id ? this.mlo.id : 0,
        firstName: this.mlo.firstName ? this.mlo.firstName : '',
        lastName: this.mlo.lastName ? this.mlo.lastName : '',
        position: this.mlo.position ? this.mlo.position : '',
        nmls: this.mlo.nmls ?  this.mlo.nmls.toString() : '',
        phone: this.mlo.phone ? this.mlo.phone : '',
        email: this.mlo.email ? this.mlo.email : '',
        signature: this.mlo.signature ? this.mlo.signature : ''
      },
      ui: {
        saving: false
      }
    }
  },
  methods: {
    saveMlo() {
      this.ui.saving = true
      setTimeout(() => { this.ui.saving = false; this.$emit('update', this.agent) }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.signature-preview {

  &__label,
  &__body {
    letter-spacing: 0;
  }

  &__label {
    color: #000000;
    font-size: 14px;
    line-height: 18px;
  }

  &__body {
    color: #000000;
    font-family: "Safiar Signature";
    font-size: 42px;
    line-height: 36px;
    margin-top: 18px;
  }
}
</style>
