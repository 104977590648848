<template>
  <div class="approval__preview">
    <page-spinner v-if="loading" />
    <pdf :src="src" :page="1" class="approval__pdf" :class="{'approval__pdf_active': loading === false}" />
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { getBackendUrl } from "@/utils/backendUrl";
import PageSpinner from "../../../components/pageSpinner";

export default {
  name: "PreApprovalLetterPreview",
  components: {PageSpinner, pdf },
  data() {
    return {
      loading: true,
      src: null,
      formData: {}
    };
  },
  methods: {
    getPreview() {
      this.loading = true;
      this.src = pdf.createLoadingTask({
        url:
        getBackendUrl() +
          "/api/v1/pre-approved-letter/preview-pdf?" +
          this.convertObjectToQueryParams(this.formData),
          withCredentials: false,
          httpHeaders: {
            'api-token': this.$store.getters.getToken ?? '',
          }
      });
      this.src.promise.then(() => {
        this.loading = false;
      });
    }
  },
  mounted: function() {
    this.$root.$on("show-letter-preview", formData => {
      this.formData = formData;
      this.getPreview();
    });
  }
};
</script>

<style lang="scss" scoped>
.approval__preview {
  padding: 30px;
}

.approval__pdf {
  display: none!important;
  width: 100%;
  border: 1px solid #b4b4b4;
  margin-bottom: 30px;

  &.approval__pdf_active {
    display: inline-block !important;
  }

  .annotationLayer {
    display: none;
  }
}
</style>
