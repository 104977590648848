<template>
  <div class="approval-info">
    <h2 class="approval-info__title">
      MLO
    </h2>
    <div class="form-group form-group_stronger full-width">
      <label for="mloField">Select MLO</label>
      <multiselect
        v-model="selectedMLO"
        placeholder="Select Mlo"
        id="mloField"
        track-by="id"
        label="fullName"
        class="full-width w-100"
        :options="mloList"
        :searchable="true"
        :close-on-select="true"
        :allow-empty="false"
        :show-labels="false"
        @select="updateMlo"
      >
      </multiselect>
    </div>
    <div class="selected-mlo">
      <button class="btn btn-control" @click="ui.editModal = true">
        <img src="@/assets/icons/icon-edit-normal.svg" alt="" />
      </button>
      <div
        class="d-flex align-items-center justify-content-center w-100"
        v-if="ui.loading"
      >
        <page-spinner />
      </div>
      <div class="selected-mlo__avatar" v-if="ui.loading === false">
        <img :src="agentPhoto(selectedMLO.profile)" alt="" />
      </div>
      <div class="selected-mlo__info" v-if="ui.loading === false">
        <div class="selected-mlo__name">
          <span>{{ selectedMLO.fullName }}</span>
        </div>
        <div class="selected-mlo__position">
          <span>{{ selectedMLO.position }} | NMLS {{ selectedMLO.nmls }}</span>
        </div>
        <div class="selected-mlo__phone">
          <span>{{ selectedMLO.phone ? formatUSNumber(selectedMLO.phone) : "" }}</span>
        </div>
        <div class="selected-mlo__email">
          <span>{{ selectedMLO.email }}</span>
        </div>
        <div class="selected-mlo__signature">
          <span>{{ selectedMLO.signature }}</span>
        </div>
      </div>
    </div>
    <transition mode="out-in" name="component-fade">
      <pre-approval-letter-mlo-edit-modal
        :mlo="selectedMLO"
        v-if="ui.editModal"
        @update="updateMlo"
        @close="ui.editModal = false"
      />
    </transition>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PageSpinner from "../../../components/pageSpinner";
import PreApprovalLetterMloEditModal from "../modals/PreApprovalLetterMloEditModal";

export default {
  name: "ProApprovalLetterMLO",
  components: { PreApprovalLetterMloEditModal, PageSpinner, Multiselect },
  props: {
    selectedAgent: { type: Object }
  },
  data() {
    return {
      selectedMLO: this.selectedAgent ? this.selectedAgent : null,
      currentAgentId: this.$store.getters.getUserId,
      mloList: [],
      ui: {
        loading: false,
        editModal: false
      }
    };
  },
  methods: {
    getAgent() {
      this.ui.loading = true;

      this.$http
        .get(`/api/v1/pre-approved-letters/create`)
        .then(res => {
          this.mloList = res.data.agents;
          if (this.selectedMLO === null) {
            this.selectedMLO = this.mloList.find(el => {
              return el.id === this.currentAgentId ? el : null;
            });
            this.updateMlo(this.selectedMLO);
          }

          this.ui.loading = false;
        })
        .catch();
    },
    updateMlo(mloEdited) {
      this.ui.loading = true;
      this.selectedMLO.id = mloEdited.id;
      this.selectedMLO.fullName = mloEdited.firstName + " " + mloEdited.lastName;
      this.selectedMLO.firstName = mloEdited.firstName;
      this.selectedMLO.lastName = mloEdited.lastName;
      this.selectedMLO.position = mloEdited.position;
      this.selectedMLO.nmls = mloEdited.nmls;
      this.selectedMLO.phone = mloEdited.phone;
      this.selectedMLO.email = mloEdited.email;
      this.selectedMLO.signature = mloEdited.signature;

      this.ui.loading = false;
      this.ui.editModal = false;

      this.$emit("update-agent", this.selectedMLO);
    }
  },
  beforeMount() {
    this.getAgent();
  }
};
</script>

<style lang="scss" scoped>
.approval-info {
  padding: 20px 30px 23.5px 30px;

  &__title {
    span {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  .selected-mlo {
    position: relative;
    display: flex;
    padding: 24px 16px;
    margin-top: 18px;
    border: 1px solid #dedede;
    border-radius: 4px;
    background-color: #ffffff;

    .btn-control {
      position: absolute;
      right: 2%;
      top: 7%;
    }

    span {
      display: inline-block;
    }

    &__avatar {
      margin-right: 12px;

      img {
        height: 72px;
        width: 72px;
        border: 0.73px solid #b4b4b4;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &__name,
    &__position,
    &__phone,
    &__email,
    &__signature {
      word-break: break-all;

      span {
        color: #000000;
        letter-spacing: 0;
      }
    }

    &__position,
    &__phone,
    &__email {
      word-break: break-all;

      span {
        font-size: 12px;
        line-height: 20px;
        margin-top: 4px;
      }
    }

    &__phone,
    &__email {
      word-break: break-all;

      span {
        opacity: 0.7;
      }
    }

    &__name {
      word-break: break-all;

      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    &__signature {
      word-break: break-all;

      span {
        font-family: "Safiar Signature";
        font-size: 36px;
        line-height: 36px;
        margin-top: 16px;
      }
    }
  }

  ::v-deep {
    .multiselect {
      height: 36px;
      width: 200px;
      @media screen and (max-width: 568px) {
        max-width: 50%;
        min-width: 100%;
      }

      &.multiselect--active {
        .multiselect__tags {
          transition: all 0.3s;
          border: 1px solid rgba(38, 111, 229, 0.5);
          box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        }
      }

      &__tags {
        min-height: 36px;
        max-height: 36px;
        padding: 7px 40px 0 8px;
        transition: all 0.3s;
        border: 1px solid #b4b4b4;
        border-radius: 4px;
        background-color: #ffffff;

        &:hover {
          border: 1px solid rgba(38, 111, 229, 0.2);
          box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        }
      }

      &__select {
        min-height: 36px;
        max-height: 36px;

        &:before {
          top: 10%;
          position: relative;
          right: 0;
          color: #999;
          border: solid rgba(0, 0, 0, 1);
          margin-top: 4px;
          border-width: 0px 1px 1px 0;
          display: inline-block;
          padding: 3px;
          content: "";
          transform: rotate(44deg);
        }
      }

      &__single {
        color: rgba(0, 0, 0, 0.46);
        @media screen and (max-width: 568px) {
          font-size: 9px;
          white-space: nowrap;
        }
      }

      &__placeholder {
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
      }

      &__option {
        padding: 10px 12px;
        min-height: 36px;
        transition: all 0.3s;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }

        &--highlight {
          background: #f3f3f3;
          line-height: 20px;
          font-size: 14px;
          color: #000;
          @media screen and (max-width: 568px) {
            font-size: 9px;
          }
        }
      }
    }
  }
}
</style>
