<template>
  <div class="approval-info border-bottom-light">
    <h2 class="approval-info__title">
      Letter
    </h2>
    <base-field
      v-model="subject"
      field-id="subjectField"
      class="form-group_stronger form-group_small full-width"
      type-field="text"
      label="Subject"
    />

    <base-field
      v-model="to"
      field-id="toField"
      class="form-group_stronger form-group_small full-width mt-3"
      type-field="text"
      label="To"
    />
  </div>
</template>

<script>
import BaseField from "../../../components/form/BaseField";
export default {
  name: "ProApprovalLetter",
  components: { BaseField },
  props: {
    letterSubject: { type: String },
    letterTo: { type: String }
  },
  data() {
    return {
      subject: this.letterSubject ? this.letterSubject : "",
      to: this.letterTo ? this.letterTo : ""
    };
  },
  watch: {
    subject(val) {
      this.$emit("update-letter-subject", val);
    },
    to(val) {
      this.$emit("update-toField", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.approval-info {
  padding: 20px 30px 23.5px 30px;

  &__title {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
</style>
